// @flow


export function getProductNumsBySiteNumber(siteNumber: number) {
  switch (siteNumber) {
    case 80:
      return [ 243, 274, ];
    case 10:
    case 20:
      return [ 273, 274, ];
    case 85:
      return [ 239, ];
    default:
      return [];
  }
}


export function getSiteAliasBySiteNumber(siteNumber: number) {
  switch (siteNumber) {
    case 85:
      return 'HDC';
    case 10:
    case 20:
      return 'TM';
    default:
      return 'HTZ';
  }
}

// Function for sorting the products.
// Primary sorting by product number, from higher to lower.
// Secondary sorting by start date, from later to earlier.
export function productsSort(product1, product2) {
  if (product2.prodNum > product1.prodNum) return 1;
  if (product2.prodNum < product1.prodNum) return -1;
  if (new Date(product2.startDate) > new Date(product1.startDate)) return 1;
  if (new Date(product2.startDate) < new Date(product1.startDate)) return -1;
  return 0;
}
