// @flow

import { type ComponentPropResponsiveObject, type StyleProps, } from '@haaretz/htz-css-tools';
import * as React from 'react';
import getLayoutContainerMiscStyles from './getLayoutContainerMiscStyles';

import type { attrFlowType, } from '../../flowTypes/attrTypes';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';
import type { PaginatorStylePropsType, } from '../List/Paginator';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LayoutContainer from '../PageLayout/LayoutContainer';
import LayoutRow from '../PageLayout/LayoutRow';
import Section from '../AutoLevels/Section';

type ListViewType = {
  attrs: ?attrFlowType,
  children: ?React.Node,
  disableWrapper: boolean,
  keepHeadingLevel: boolean,
  id: ?string,
  innerBackgroundColor: ColorPropType,
  outerBackgroundColor: ?ColorPropType,
  /** The padding applied to the LayoutContainer */
  marginTop: number | string | Array<ComponentPropResponsiveObject<number | string>>,
  areasTemplate: ?string | Array<ComponentPropResponsiveObject<string>>,
  colTemplate: ?string | Array<ComponentPropResponsiveObject<string>>,
  rowTemplate: ?string | Array<ComponentPropResponsiveObject<string>>,
  gridGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  colGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  rowGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  padding: ?string | Array<ComponentPropResponsiveObject<string>>,
  miscStyles: ?StyleProps,
  sectionMiscStyles: ?StyleProps,
  Paginator: ?React.ComponentType<PaginatorStylePropsType>,
};

ListView.defaultProps = {
  attrs: null,
  children: null,
  disableWrapper: false,
  keepHeadingLevel: false,
  id: null,
  innerBackgroundColor: 'transparent',
  outerBackgroundColor: null,
  marginTop: [ { until: 's', value: '6rem', }, { from: 's', value: '8rem', }, ],
  areasTemplate: null,
  colTemplate: null,
  rowTemplate: null,
  gridGap: [ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
  colGap: null,
  rowGap: null,
  padding: [ { until: 's', value: '0 2rem', }, { from: 's', value: '0 4rem', }, ],
  miscStyles: null,
  sectionMiscStyles: null,
  Paginator: null,
};
export default function ListView({
  attrs,
  children,
  disableWrapper,
  keepHeadingLevel,
  id,
  innerBackgroundColor,
  outerBackgroundColor,
  marginTop,
  padding,
  gridGap,
  colGap,
  rowGap,
  colTemplate,
  rowTemplate,
  areasTemplate,
  miscStyles,
  sectionMiscStyles,
  Paginator,
}: ListViewType): React.Node {
  const Wrapper = disableWrapper ? React.Fragment : LayoutRow;

  return (
    <ErrorBoundary>
      <Wrapper
        {...(disableWrapper ? {} : {
          attrs,
          id,
          tagName: 'section',
          namedBgc: outerBackgroundColor,
          miscStyles: getSectionMiscStyles(marginTop, sectionMiscStyles),
        })}
      >
        <LayoutContainer
          attrs={disableWrapper ? attrs : null}
          id={disableWrapper ? id : null}
          namedBgc={innerBackgroundColor}
          miscStyles={getLayoutContainerMiscStyles({
            gridGap,
            colGap,
            rowGap,
            colTemplate,
            rowTemplate,
            areasTemplate,
            padding,
            miscStyles,
          })}
        >
          {keepHeadingLevel ? children : <Section isFragment>{children}</Section>}
        </LayoutContainer>
        {Paginator ? (
          <Paginator
            gridGap={gridGap}
            colGap={colGap}
            rowGap={rowGap}
            padding={padding}
            namedBgc={innerBackgroundColor}
          />
        ) : null}
      </Wrapper>
    </ErrorBoundary>
  );
}

// ////////////////////////////////////////////////////////////////// //
//                          Helper Functions                          //
// ////////////////////////////////////////////////////////////////// //

function getSectionMiscStyles(marginTop, miscStyles) {
  return miscStyles ? Object.assign({ marginTop, }, miscStyles) : { marginTop, };
}
