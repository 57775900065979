/* global document */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { createComponent, } from 'react-fela';
import { parseComponentProp, } from '@haaretz/htz-css-tools';
import { attrsPropType, } from '../../../propTypes/attrsPropType';
import { responsivePropBaseType, } from '../../../propTypes/responsivePropBaseType';

const styles = ({ theme, isContentEditable, isTextArea, height, }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  ...(isTextArea ? { resize: 'none', } : {}),
  ...(isContentEditable
    ? { overflow: 'auto', wordWrap: 'break-word', wordBreak: 'break-word', }
    : {}),
  fontSize: 'inherit',
  width: '100%',
  cursor: 'pointer',
  ':focus': {
    outline: 'none',
    cursor: 'text',
  },
  extend: [
    isTextArea || isContentEditable
      ? parseComponentProp(undefined, height, theme.mq, setHeight, theme)
      : [],
  ],
});

function setHeight(prop, height, theme) {
  return {
    height: height ? `${height}rem` : `${theme.inputStyle.height}rem`,
  };
}

class InputElement extends Component {
  static propTypes = {
    /**
     * Id used to connect the note to input with aria-describedby for a11y reasons,
     * default will generate random id
     */
    ariaDescribedBy: PropTypes.string,
    /**
     * aria-label attribute to add to form in case the label is hidden,
     * will be passed automatically the value of label prop if labelHidden=true prop is given
     */
    ariaLabel: PropTypes.string,
    /**
     * An object of attrbutes to set on the DOM element.
     * Passed to the underlying input/textarea element in this component
     */
    attrs: attrsPropType,
    /** Class(es) to be added to the DOM element.
     * Automatically generated by Fela, do not enter manually.
     */
    className: PropTypes.string,
    /**
     * The initial value of an uncontrolled `<TextInput />`.
     * Only relevant when using an uncontrolled input
     * Allows specifying the initial value but leaving subsequent
     * updates uncontrolled
     */
    defaultValue: PropTypes.string,
    /**
     * A callback function to allow parent component to get ref of input,
     * so it can focus on it when needed
     */
    refFunc: PropTypes.func,
    /** Id used to connect the label for a11y reasons, default will generate random id */
    inputId: PropTypes.string.isRequired,
    /**
     * Enables rich text capabilities by using a div with
     * a `contentEditable` attribute. Should not be used
     * in cunjunction with `isTextArea`
     */
    isContentEditable: PropTypes.bool,
    /** Is this input disabled */
    isDisabled: PropTypes.bool.isRequired,
    /**
     * Is the Input element focused as defined by the TextInput Component.
     * The parent component takes care of not losin focus clicking the format buttons
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Is this a textarea (multi line text input).
     * Should not be used in conjunction with `isContentEditable`
     */
    isTextArea: PropTypes.bool,
    /**
     * Id used to connect the label with contentEditable with
     * aria-labelledby for a11y reasons, default will generate random id
     */
    labelId: PropTypes.string,
    /**
     * The max number of characters allowed in the input
     * Adds a native html maxlength atrribute
     * doesnt work for contenteditable, if need, should be implemented
     * as a react controlled  input
     */
    maxLength: PropTypes.number,
    /**
     * The min number of characters allowed in the input
     * Adds a native html minlength atrribute
     * doesnt work for contenteditable, if need, should be implemented
     * as a react controlled  input
     */
    minLength: PropTypes.number,
    /**
     * A callback that gets called when the input blures
     */
    onBlur: PropTypes.func.isRequired,
    /**
     * A callback that gets the onChange event
     * used to update state of parent when using as react controlled input
     * should not be used in conjucntion with onInput
     * when using as a contentEditable div use only onContentEditableChange
     * @param {SyntheticEvent} evt - The event object
     */
    onChange: PropTypes.func,
    /**
     * A callback that gets the event triggered by onInput and the new innerHTML
     * value of the content editable div
     * used to update state of parent when using as react controlled input
     * should not be used in conjucntion with onInput or onChange
     * @param {SyntheticEvent} evt - The event object
     * @param {String} innerHtml - The new innerHtml value of the div
     */
    onContentEditableChange: PropTypes.func,
    /**
     * Callback that gets called when the input focuses
     * used to update state of parent if the input is focused
     */
    onFocus: PropTypes.func.isRequired,
    /**
     * A callback that gets the onInput event
     * used to update state of parent when using as react controlled input
     * should not be used in conjucntion with onChange
     * when using as a contentEditable div use only onContentEditableChange
     * @param {SyntheticEvent} evt - The event object
     */
    onInput: PropTypes.func,
    /** placeholder text */
    placeholder: PropTypes.string,
    /**
     * Gets passed automatically when isContentEditable
     * Callback that checks and updates the format buttons state in parent component
     */
    setFormatButtonsState: PropTypes.func,
    /** The html input type */
    type: PropTypes.oneOf([
      'email',
      'number',
      'password',
      'search',
      'tel',
      'text',
      'url',
    ]),
    /**
     * Value of a controlled `<TextInput />`.
     * Should never be passed manually by the consumer, but rather
     * set by the controlling component.
     */
    value: PropTypes.string,
    // Stylistic props
    /**
     * The height of an input, in rems. Only relevant when
     * `isTextArea` or `isContentEditable`.
     */
    height: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.shape({
          ...responsivePropBaseType,
          value: PropTypes.number.isRequired,
        })
      ),
    ]),
  };

  static defaultProps = {
    ariaDescribedBy: null,
    ariaLabel: null,
    attrs: null,
    className: null,
    defaultValue: null,
    height: null,
    isContentEditable: false,
    isTextArea: false,
    labelId: null,
    maxLength: null,
    minLength: null,
    onChange: null,
    onContentEditableChange: null,
    onInput: null,
    placeholder: null,
    refFunc: undefined,
    setFormatButtonsState: null,
    type: 'text',
    value: undefined,
  };

  /**
   * @param nextProps
   * When using as contenteditable, in order to keep the component in sync with controlling
   * component we update it when it loses focus,
   * We disable updating component when it isContentEditable and is focused since,
   * updating causes losing focus and resets caret position,
   * Otherwise update only if value changes
   */
  shouldComponentUpdate(nextProps) {
    if (this.props.isDisabled !== nextProps.isDisabled) return true;
    if (this.props.type !== nextProps.type) return true;
    if (this.props.isContentEditable && !nextProps.isFocused) return true;
    if (this.props.isContentEditable && nextProps.isFocused) return false;
    if (nextProps.value !== this.props.value) return true;
    return false;
  }

  handleContentEditableChange = evt => {
    if (this.props.onContentEditableChange) {
      this.props.onContentEditableChange(evt, this.inputEl.innerHTML);
    }
    this.props.setFormatButtonsState();
  };

  render() {
    const {
      ariaDescribedBy,
      ariaLabel,
      attrs,
      className,
      defaultValue,
      inputId,
      isContentEditable,
      isDisabled,
      isTextArea,
      labelId,
      maxLength,
      minLength,
      onBlur,
      onChange,
      onContentEditableChange,
      onFocus,
      onInput,
      placeholder,
      setFormatButtonsState,
      refFunc,
      type,
      value,
    } = this.props;

    if (isTextArea && isContentEditable) {
      console.warn(
        'isTextArea and isContentEditable shouldnt both be true, in such a case will render textarea'
      );
    }
    if ((onInput || onChange) && onContentEditableChange) {
      console.warn(
        'onChange or onInput shouldnt be used in conjunction with onContentEditableChange'
      );
    }

    const inputValue = value || (value === '' ? '' : defaultValue);

    const TagName = isContentEditable
      ? 'div'
      : isTextArea ? 'textarea' : 'input';
    return (
      <TagName
        {...(ariaDescribedBy ? { 'aria-describedby': ariaDescribedBy, } : {})}
        {...(ariaLabel ? { 'aria-label': ariaLabel, } : {})}
        data-test={isTextArea ? 'textarea' : 'input'}
        {...attrs}
        className={className}
        // {...(defaultValue ? { defaultValue, } : {})}
        id={inputId}
        {...(isDisabled ? { disabled: true, } : {})}
        {...(isTextArea ? {} : { type, })}
        {...(maxLength ? { maxLength, } : {})}
        {...(minLength ? { minLength, } : {})}
        onBlur={onBlur}
        {...(onChange ? { onChange: evt => onChange(evt), } : {})}
        onFocus={onFocus}
        {...(onInput && !onContentEditableChange
          ? { onInput: evt => onInput(evt), }
          : {})}
        {...(placeholder ? { placeholder, } : {})}
        {...(refFunc || isContentEditable
          ? {
            ref: el => {
              if (isContentEditable) this.inputEl = el;
              if (refFunc) refFunc(el);
            },
          }
          : {})}
        // contentEditable attrs
        {...(isContentEditable
          ? {
            contentEditable: true,
            tabIndex: isDisabled ? -1 : 0,
            role: 'textbox',
            'aria-multiline': true,
            'aria-labelledby': labelId,
            ...(typeof inputValue === 'string'
              ? { dangerouslySetInnerHTML: { __html: inputValue, }, }
              : {}),
            onInput: this.handleContentEditableChange,
            onMouseDown: evt => (isDisabled ? evt.preventDefault() : null),
            onPaste: evt => {
              evt.preventDefault();
              document.execCommand(
                'insertHTML',
                false,
                evt.clipboardData.getData('text/plain')
              );
            },
            ...(setFormatButtonsState
              ? {
                onMouseUp: setFormatButtonsState,
                onKeyUp: setFormatButtonsState,
              }
              : {}),
          }
          : typeof inputValue === 'string' ? { value: inputValue, } : {})}
      />
    );
  }
}

const StyledInputElement = createComponent(styles, InputElement, [
  'ariaDescribedBy',
  'ariaLabel',
  'attrs',
  'defaultValue',
  'inputId',
  'isContentEditable',
  'isDisabled',
  'isFocused',
  'isTextArea',
  'labelId',
  'maxLength',
  'minLength',
  'onBlur',
  'onChange',
  'onContentEditableChange',
  'onFocus',
  'onInput',
  'onPaste',
  'placeholder',
  'refFunc',
  'type',
  'setFormatButtonsState',
  'value',
]);

export default StyledInputElement;
