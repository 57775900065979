// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { ChildrenArray, Node, } from 'react';

type PropsType = {
  /**
     * The element that should be faded.
     */
  children: ChildrenArray<Node>,
  /**
   * The delay of the transition.
   */
  delay?: number,
  /**
   * The duration of the transition.
   */
  duration?: number,
  /**
   * The duration of the transition-In (Trumps the `duration` prop).
   */
  durationIn?: number,
  /**
   * The duration of the transition-Out (Trumps the `duration` prop).
   */
  durationOut?: number,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles?: StyleProps,
  /**
   * CSS class name created by react-fela
   */
  className?: string,
  render?: () => void,
  /**
   * The trigger of the transition.
   */
  show: boolean,
};

function toggleStyle({ theme, isVisible, duration, delay, durationIn, durationOut, miscStyles, }) {
  return {
    opacity: isVisible ? 1 : 0,
    ...(!((isVisible && durationIn === 0) || (!isVisible && durationOut === 0))
      ? {
        transitionProperty: 'opacity',
        ...theme.getDuration(
          'transition',
          isVisible ? durationIn || duration : durationOut || duration
        ),
        ...theme.getTimingFunction('transition', 'linear'),
        ...(delay != null ? theme.getDelay('transition', delay) : {}),
      }
      : {}),
    extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
  };
}

export default function ToggleFade({ children, render, show, durationIn = 0, durationOut = 0, duration = 0, delay = 0, className, miscStyles, }: PropsType) {
  const [ animating, setAnimating, ] = React.useState(false);
  const [ isVisible, setIsVisible, ] = React.useState(show);
  const { css, } = useFela({ isVisible, animating, duration, delay, durationIn, durationOut, miscStyles, });

  React.useEffect(() => {
    if (isVisible !== show) {
      setIsVisible(show);
    }
  }, [ isVisible, show, ]);

  return (
    <div className={`${`${className} ` || ''}${css(toggleStyle)}`} onTransitionEnd={() => setAnimating(false)}>
      { children || (render && render({ animating, }))}
    </div>
  );
}
