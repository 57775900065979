// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useQuery, } from 'react-apollo';
import gql from 'graphql-tag';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import ToggleFade from '../Transitions/ToggleFade';

type PropsType = {
  /**
   * Should the element be animated out.
   */
  animate?: boolean,
  /**
   * Nodes that ought to be hidden in 'Zen mode'.
   */
  children: ChildrenArray<any>,
  /**
   * Should the element be hidden or removed (default).
   */
  hide?: boolean,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles?: StyleProps;
  /**
   * CSS class name created by react-fela
   */
  className?: string;
};

export const ZEN_QUERY = gql`
  query GetZenStatus {
    zenMode @client
  }
`;

function wrapperStyle({ theme, zenMode, miscStyles, }) {
  return {
    display: zenMode ? 'none' : 'block',
    extend: [
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

/**
 * This wrapper removes or hides its children when the user enters `Zen Mode`.
 * It listens to the field ZenMode at the Apollo store and hide/show according to the change.
 */
export default function Zen({ children, animate = false, hide = false, miscStyles = null, className}: PropsType) {
  const { loading, error, data, } = useQuery(ZEN_QUERY);
  const { zenMode, } = data || { zenMode: false, };
  const { css, } = useFela({ zenMode, miscStyles, });

  if (loading || error) {
    return null;
  }

  if (hide) {
    return (<div className={`${`${className} ` || ''}${css(wrapperStyle)}`}>{children}</div>);
  }

  if (animate) {
    return (
      <ToggleFade duration={0} show={!zenMode} miscStyles={miscStyles} className={className}>
        {children}
      </ToggleFade>
    );
  }

  if (!zenMode) {
    return (<div className={`${`${className} ` || ''}${css(wrapperStyle)}`}>{children}</div>);
  }

  return null;
}
