import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';
import { textInputVariantType, } from '../TextInput/textInputVariantType';

import { stylesPropType, } from '../../propTypes/stylesPropType';
import HtzLink from '../HtzLink/HtzLink';

const TextInputVariants = textInputVariantType;

const propTypes = {
  /** true if there is a validation error */
  // used for style change
  // eslint-disable-next-line react/no-unused-prop-types
  isError: PropTypes.bool,
  /**
   * Id used to connect the note to input with aria-describedby for a11y reasons,
   * default will generate random id
   */
  noteId: PropTypes.string,
  /** the note text, parent component responsible for changing the text when there is an error */
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.element, PropTypes.node, ]),
  /** The TextInput stylistic variant */
  // used for styling
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.oneOfType([
    TextInputVariants,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: TextInputVariants.isRequired,
      })
    ),
  ]),
  linkAfterText: PropTypes.shape({ link: PropTypes.string, text: PropTypes.string, }),
  /** Enable Note component to use another behavior only for CommentForm */
  isCommentForm: PropTypes.bool,

  miscStyles: stylesPropType,
};
const defaultProps = {
  isError: false,
  isCommentForm: false,
  noteId: null,
  text: null,
  linkAfterText: null,
  variant: 'primary',
  miscStyles: null,
};

const linkAfterTextStyle = ({ theme, }) => ({
  color: theme.color('noteLink', 'color'),
  ':visited': {
    color: theme.color('noteLink', 'color'),
  },
  ':hover': {
    color: theme.color('noteLink', 'color'),
  },
});

function setVariant(prop, variant, getColor, isError) {
  return {
    color: isError
      ? getColor('input', `${variant}ErrorTextNote`)
      : getColor('input', `${variant}TextNote`),
  };
}

function Note({ variant, isError, text, noteId, isCommentForm, miscStyles, linkAfterText, }) {
  const { css, theme, } = useFela();
  return (
    <span
      className={css({
        display: 'block',
        fontFamily: theme.fontStacks[theme.framedFont],
        extend: [
          isCommentForm ? theme.type(-2, { lines: 4, }) : theme.type(-3),
          parseComponentProp(undefined, variant, theme.mq, setVariant, theme.color, isError),
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
      id={noteId}
    >
      {linkAfterText ? (
        <>
          {text}
          {' '}
          <HtzLink
            className={css(linkAfterTextStyle)}
            href={linkAfterText.link}
            content={linkAfterText.text}
          />
        </>
      ) : text}
    </span>
  );
}

Note.propTypes = propTypes;
Note.defaultProps = defaultProps;

export default Note;
