import Router from 'next/router';

export default (
  responseEnd,
  responseWriteHead,
  redirectUri,
  responseCode,
  href,
  pathname,
) => {
  if (responseEnd) {
    // server
    // 303: "See other"
    responseWriteHead(responseCode, { Location: redirectUri, });
    responseEnd();
  }
  else {
    // In the browser, we just pretend like this never even happened ;)
    Router.push({
      pathname,
      query: {
        path: `${href.pathname}`,
      },
    },
    `${href.pathname}`
    );
  }
};
