// @flow
import config from 'config';
import { productsSort, getProductNumsBySiteNumber, } from './general';
import type { ProductType, } from '../PersonalAreaTypes';
import checkSiteFromConfig from '../../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

const siteNumber = config.has('siteNumber') ? config.get('siteNumber') : 80;

function dayDifference(date1, date2) {
  const milliSecondsInOneDay = 86400000; // 1000 * 60 * 60 * 24
  const diff = date1.getTime() - date2.getTime();
  return diff > 0 ? Math.ceil(diff / milliSecondsInOneDay) : Math.floor(diff / milliSecondsInOneDay);
}

function formatDate(strDate) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  if (isHDC) {
    return new Date(strDate).toLocaleDateString('en-US', options);
  }
  return new Date(strDate).toLocaleDateString('he-IL', options);
}

export const getSubscriptionName = ({
  standByStage,
  prodNum,
  proposalPeriod,
  isTrial,
  isApple,
  isAndroid,
}: {
    standByStage: Object,
    prodNum: number,
    proposalPeriod: number,
    isTrial: boolean,
    isApple: boolean,
    isAndroid: boolean,
  }): string => {
  const postfixKey = isTrial ? 'trial' : proposalPeriod === 1 ? 'monthly' : proposalPeriod === 12 ? 'yearly' : 'other';

  let subscriptionName = standByStage.subscriptions.prefix;
  subscriptionName += standByStage.subscriptions.products[prodNum];
  subscriptionName += standByStage.subscriptions.postfix[postfixKey];

  if (isAndroid) {
    subscriptionName += standByStage.subscriptions.android;
  }

  if (isApple) {
    subscriptionName += standByStage.subscriptions.apple;
  }

  subscriptionName += '.';

  return subscriptionName;
};

export type ProductInfoType = {
  prodNum: number,
  isTrial: boolean,
  proposalPeriod: number,
  isStoppedSubscription: boolean,
  isRegister: boolean,
  isApple: boolean,
  isAndroid: boolean,
  isCancelled: boolean,
  futureEndDate: ?string,
  isComplexed: boolean,
  finishStageDate: string,
  finishStageDateFormatted: string,
  isFree: boolean,
  isPaying: boolean,
  isFullPrice: boolean,
  hasDebt: boolean,
  startDate: ?string,
  currency: ?string,
}

export const getProductInfo = (
  products: ProductType[] = [],
): ProductInfoType => {
  const productNums = getProductNumsBySiteNumber(siteNumber);
  let prodNum = 0;
  let isTrial = false;
  let proposalPeriod = 0;
  let futureEndDate = null;
  let finishStageDate = new Date();
  let startDate = null;
  let currency = null;

  // note: this code is not valid if you want to check user׳s subscription from app stores in a
  // different website (like tm paying user is logging in to htz website)

  const product = products.filter(x => productNums.includes(x.prodNum)).sort((a, b) => productsSort(a, b)).find(x => x.status === 'SUBSCRIBED');
  const isRegister = products.length === 0;
  let isFree = false;
  let isPaying = false;
  let isApple = false;
  let isAndroid = false;
  const isCancelled = false;
  let isComplexed = false;
  let isFullPrice = false;
  const hasFinishedStageDate = !!product?.finishStageDate;
  const hasDebt = products?.filter(p => p.debtActive === true || p.debtAmount > 0).length > 0;
  let subscriptionExpiresSoon = false;

  if (product) {
    proposalPeriod = product.proposalPeriod;
    isTrial = product.isTrial;
    prodNum = product.prodNum;
    isApple = product.connectionType === 763;
    isAndroid = product.connectionType === 776;
    isFree = product.payersSon === true || product.payProp === 1 || isTrial;
    isPaying = !isFree;
    isComplexed = [ 274, ].includes(product.prodNum);
    isFullPrice = product.isFullPrice;
    if (product.startDate) {
      startDate = new Date(product.startDate);
    }
    if (product.finishStageDate) {
      finishStageDate = new Date(product.finishStageDate);
    }
    if (product.futureEndDate) {
      futureEndDate = new Date(product.futureEndDate);
      subscriptionExpiresSoon = dayDifference(new Date(), futureEndDate) >= -30;
    }
    if (product.currency) {
      currency = product.currency;
    }
  }

  const isStoppedSubscription = !product && products.length > 0;

  let stoppedFinishDate = null;
  const stoppedProduct = products.filter(x => productNums.includes(x.prodNum)).find(x => x.status === 'STOPPED');

  if (stoppedProduct && stoppedProduct.finishStageDate) {
    stoppedFinishDate = new Date(stoppedProduct.finishStageDate);
  }

  return {
    prodNum,
    isTrial,
    proposalPeriod,
    isStoppedSubscription,
    isPaying,
    isApple,
    isAndroid,
    isRegister,
    isFree,
    isFullPrice,
    isCancelled,
    hasDebt,
    futureEndDate: futureEndDate ? futureEndDate.toLocaleDateString() : futureEndDate,
    futureEndDateFormatted: futureEndDate ? formatDate(futureEndDate) : futureEndDate,
    isComplexed,
    finishStageDate: finishStageDate.toLocaleDateString(),
    finishStageDateFormatted: hasFinishedStageDate ? formatDate(finishStageDate) : null,
    stoppedFinishDate,
    hasFinishedStageDate,
    startDate: startDate ? startDate.toLocaleDateString() : startDate,
    subscriptionExpiresSoon,
    currency,
  };
};
