// @flow

type LayoutContainerMiscStylesType = {
  gridGap?: any,
  colGap?: any,
  rowGap?: any,
  colTemplate?: any,
  rowTemplate?: any,
  areasTemplate?: any,
  padding?: any,
  miscStyles?: any,
};

export default function getLayoutContainerMiscStyles({
  gridGap,
  colGap,
  rowGap,
  colTemplate,
  rowTemplate,
  areasTemplate,
  padding,
  miscStyles,
}: LayoutContainerMiscStylesType) {
  const hasOthers = padding != null
    || gridGap != null
    || colGap != null
    || rowGap != null
    || colTemplate
    || rowTemplate
    || areasTemplate;

  if (gridGap != null && (colGap != null || rowGap != null)) {
    console.warn(
      `You are trying to set both the "gridGap" shorthand and one of its longhand counterparts ("colGap" or "rowGap").
      With atomic css, where the order of the cascade isn't guaranteed, this could cause hard to debug bugs.
      Please consider not mixing longhand and shorthand properties.
      `
    );
  }

  type Styles = {
    display: 'grid',
    padding?: any,
    gridGap?: any,
    gridColumnGap?: any,
    gridRowGap?: any,
    gridTemplateColumns?: any,
    gridTemplateRows?: any,
    gridTemplateAreas?: any,
  };
  const styles: Styles = { display: 'grid', };
  if (hasOthers) {
    if (padding) styles.padding = padding;
    if (gridGap) styles.gridGap = gridGap;
    if (colGap) styles.gridColumnGap = colGap;
    if (rowGap) styles.gridRowGap = rowGap;
    if (colTemplate) styles.gridTemplateColumns = colTemplate;
    if (rowTemplate) styles.gridTemplateRows = rowTemplate;
    if (areasTemplate) styles.gridTemplateAreas = areasTemplate;
  }

  return miscStyles ? Object.assign(styles, miscStyles) : styles;
}
