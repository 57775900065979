// @flow
import React from 'react';
import { FelaComponent, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';

import embedTypes from './utils/embedTypes';
import Debug from '../Debug/Debug';
import Caption from '../Caption/Caption';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import WebViewPreloader from '../WebViewPreloader/WebViewPreloader';
import useWebViewChecker from '../../hooks/useWebViewChecker';

const embedsWithLoader = [
  'Facebook',
  'com.polobase.FacebookComments',
  'GoogleMaps',
  'Instagram',
  'Twitter',
  'Youtube',
];

type Props = {
  source: string,
  embedType: string,
  settings: ?Object,
  showCaption: ?boolean,
  asGif?: ?boolean,
  inputTemplate?: string,
  caption: ?string,
  credit: ?string,
  embedWrapperMiscStyles: ?Object,
  item?: ?Object,
};

Embed.defaultProps = {
  settings: null,
  showCaption: true,
  caption: null,
  credit: null,
  embedWrapperMiscStyles: null,
  item: null,
  asGif: false,
  inputTemplate: null,
};

function Embed({
  showCaption,
  caption,
  credit,
  embedWrapperMiscStyles,
  item,
  asGif,
  ...props
}: Props): Node {
  const content = item && typeof item === 'object' ? item : props;
  const Component = embedTypes(content?.inputTemplate);
  const isWebView = useWebViewChecker();
  const [ loaded, setLoaded, ] = React.useState(false);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isWebView && !loaded) {
      const timeout = setTimeout(() => { setLoaded(true); }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [ isWebView, loaded, ]);

  return Component ? (
    <ErrorBoundary>
      <FelaComponent
        style={({ theme, }) => ({
          position: 'relative',
          width: '100%',
          extend: [
            ...(embedWrapperMiscStyles
              ? parseStyleProps(embedWrapperMiscStyles, theme.mq, theme.type)
              : []),
          ],
        })}
      >
        {embedsWithLoader.includes(content.inputTemplate) && !loaded ? <WebViewPreloader /> : null}
        <Component
          {...content}
          onLoadCallback={(...args) => {
            setLoaded(true);
          }}
          {...(content?.inputTemplate === 'Facebook' ? {
            settings: {
              height: content.height,
              showText: content.showText,
              width: content.width,
            },
          } : {})}
          {...(content?.inputTemplate === 'Youtube' ? {
            asGif,
          } : {})}
        />
      </FelaComponent>
      {showCaption && (caption || credit) ? <Caption caption={caption} credit={credit} /> : null}
    </ErrorBoundary>
  ) : (
    <Debug>
      <p>{`Embed ${content.inputTemplate} is not supported`}</p>
    </Debug>
  );
}

export default Embed;
