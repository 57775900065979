import React from 'react';
import PropTypes from 'prop-types';
import { createComponent, } from 'react-fela';
import { parseStyleProps, parseComponentProp, } from '@haaretz/htz-css-tools';
import { stylesPropType, } from '../../../propTypes/stylesPropType';
import { textInputVariantType, } from '../textInputVariantType';
import { responsivePropBaseType, } from '../../../propTypes/responsivePropBaseType';

const propTypes = {
  /** Class(es) to be added to the DOM element.
   * Automatically generated by Fela, do not enter manually.
   */
  className: PropTypes.string,
  /**
   * is the abbr wrapped in <sup> tags
   */
  isSup: PropTypes.bool,
  /**
   * Used to mark an input as required, adds an abbr html element after the label
   */
  requiredText: PropTypes.shape({
    /** long string will show when hovering over the Abbr component */
    long: PropTypes.string.isRequired,
    /** the short string will show after the label text */
    short: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType, // eslint-disable-line react/no-unused-prop-types
  /** The parent `<TextInput />`'s stylistic variant */
  // eslint-disable-next-line react/no-unused-prop-types
  variant: PropTypes.oneOfType([
    textInputVariantType,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: textInputVariantType.isRequired,
      })
    ),
  ]).isRequired,
};
const defaultProps = {
  className: null,
  isSup: true,
  miscStyles: null,
  variant: 'primary',
};

const styles = ({ theme, miscStyles, variant, }) => ({
  '[title]': {
    textDecoration: 'none',
  },
  extend: [
    parseComponentProp(undefined, variant, theme.mq, setVariant, theme.color),
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

function setVariant(prop, variant, getColor) {
  return {
    color: getColor('input', `${variant}Abbr`),
  };
}

function Abbr({ className, isSup, requiredText, }) {
  return (
    <abbr className={className} title={requiredText.long}>
      {' '}
      {isSup ? <sup>{requiredText.short}</sup> : requiredText.short}
    </abbr>
  );
}

Abbr.propTypes = propTypes;
Abbr.defaultProps = defaultProps;

const StyledAbbr = createComponent(styles, Abbr, [
  'requiredText',
  'isSup',
  'variant',
]);

export default StyledAbbr;
